import Button from '@mui/material/Button';
import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import styled from 'styled-components';
import { useUpdateUser } from '../../api/hooks/use-update-user';
import { Loader } from '../../components/loader';
import { spacing } from '../../components/styled';
import { NavigationHeader } from '../../features/shared/components/navigation-header';
import {
  ActionButton,
  HorizontalWrapper,
} from '../../features/shared/components/styled';
import { AppSectionWrapper } from '../../shared/components/section-wrapper';
import { useAppDispatch, useAppSelector } from '../../store';
import { setToken } from '../../store/app-slice';
import { selectLoggedInUser } from '../../store/user-selectors';
import { setLoggedInAccount, setLoggedInUser } from '../../store/user-slice';
import { UserRole } from '../../types';
import { loginRoute } from '../routes';
import { PasswordInputs } from './components/password-inputs';

export const UserOverview = () => {
  const navigate = useNavigate();
  const loggedInUser = useAppSelector(selectLoggedInUser);
  const [unsavedChanges, setUnsavedChanges] = useState(false);
  const [role, setRole] = useState<UserRole>(loggedInUser?.role ?? 'owner');
  const [showPasswordInput, setShowPasswordInput] = useState(false);
  const dispatch = useAppDispatch();
  const { loading, updateUserData } = useUpdateUser();

  if (!loggedInUser || loading) {
    return <Loader />;
  }

  const handleUpdateUser = async () => {
    if (loggedInUser && unsavedChanges) {
      if (loggedInUser.accountId) {
        await updateUserData(
          {
            ...loggedInUser,
            role,
            accountId: loggedInUser.accountId,
          },
          true,
          false
        );
      } else {
        await updateUserData({ ...loggedInUser, role }, true, false);
      }
      setUnsavedChanges(false);
    }
  };

  const handleCancel = () => {
    setRole(loggedInUser.role);
    setUnsavedChanges(false);
  };

  const handleLogout = () => {
    localStorage.removeItem('token');
    navigate(loginRoute);
    dispatch(setLoggedInAccount(null));
    dispatch(setLoggedInUser(null));
    dispatch(setToken(null));
  };

  return (
    <div>
      <NavigationHeader />
      <AppSectionWrapper>
        <Container>
          <h2>User Details</h2>
          <Button onClick={handleLogout}>Logout</Button>
          {loggedInUser && (
            <Content>
              <p>{`Name: ${loggedInUser.firstName} ${loggedInUser.lastName}`}</p>
              <p>{`Email: ${loggedInUser.email}`}</p>
              {loggedInUser?.id === loggedInUser.id && (
                <PasswordWrapper>
                  {!showPasswordInput ? (
                    <Button onClick={() => setShowPasswordInput(true)}>
                      Update Password
                    </Button>
                  ) : (
                    <PasswordInputs
                      setShowPasswordInput={setShowPasswordInput}
                    />
                  )}
                </PasswordWrapper>
              )}
              {unsavedChanges && (
                <HorizontalWrapper>
                  <ActionButton cancel={'true'} onClick={handleCancel}>
                    Cancel
                  </ActionButton>
                  <ActionButton onClick={handleUpdateUser}>Update</ActionButton>
                </HorizontalWrapper>
              )}
            </Content>
          )}
        </Container>
      </AppSectionWrapper>
    </div>
  );
};

const Container = styled.div`
  width: 100%;
  margin-top: ${spacing.lg};
  margin-bottom: ${spacing.lg};
`;

const Content = styled.div`
  margin: ${spacing.xl} 0;
`;

const PasswordWrapper = styled.div`
  margin-bottom: ${spacing.md};
`;
