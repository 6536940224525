import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import styled from 'styled-components';
import { generateMenu } from '../../../api';
import { useUpdatePub } from '../../../api/hooks/use-update-pub';
import { HappyHours } from '../../../components/happy-hours';
import { Loader } from '../../../components/loader';
import { spacing } from '../../../components/styled';
import { CustomHeading2 } from '../../../components/styled/headings';
import { ActionButton } from '../../../features/shared/components/styled';
import {
  convertToHappyHoursByDay,
  TimeFormat,
  uniqueHappyHourTimes,
} from '../../../helpers/happy-hour-times';
import { useAppDispatch, useAppSelector } from '../../../store';
import {
  selectActivePub,
  selectUnpublishedChanges,
} from '../../../store/app-selectors';
import { setUnpublishedChanges } from '../../../store/app-slice';
import { HappyHourOptions } from '../new/happy-hours/happy-hours-options';
import { Menu } from './menu';

export const UpdateHappyHours = () => {
  const activePub = useAppSelector(selectActivePub);
  const dispatch = useAppDispatch();
  const [food, setFood] = useState(activePub?.food ?? []);
  const [drinks, setDrinks] = useState(activePub?.drinks ?? []);
  const navigate = useNavigate();
  const [showMenuInput, setShowMenuInput] = useState(false);
  const { updatePub } = useUpdatePub();
  const unpublishedChanges = useAppSelector(selectUnpublishedChanges);
  const [updatingMenu, setUpdatingMenu] = useState(false);
  const [tempHappyHours, setTempHappyHours] = useState<TimeFormat[]>([]);
  const [error, setError] = useState('');

  useEffect(() => {
    if (activePub) {
      const { happyHours, openingHours } = activePub;
      setTempHappyHours(uniqueHappyHourTimes(happyHours, openingHours));
    }
  }, [activePub]);

  if (!activePub) {
    return <Loader />;
  }

  const { openingHours } = activePub;

  const handleNewTimes = (newTimes: TimeFormat[]) => {
    dispatch(setUnpublishedChanges(true));
    setTempHappyHours(newTimes);
  };

  const handleSubmit = async (
    text?: string,
    link?: string,
    file?: File | null
  ) => {
    setUpdatingMenu(true);
    const { name, vicinity } = activePub;
    try {
      const { food, drinks } = await generateMenu({
        name,
        vicinity,
        openingHours,
        link,
        file,
        text,
      });

      dispatch(setUnpublishedChanges(true));
      setDrinks(drinks);
      setFood(food);
      setShowMenuInput(false);
      setUpdatingMenu(false);
    } catch (error) {
      setError((error as Error).message);
      setUpdatingMenu(false);
    }
  };

  const handleCancel = () => {
    dispatch(setUnpublishedChanges(false));
    navigate('../overview');
  };

  const handlePublishChanges = async () => {
    const newHours = convertToHappyHoursByDay(tempHappyHours, openingHours);
    const newPubData = { ...activePub, food, drinks, happyHours: newHours };
    await updatePub(newPubData);
    dispatch(setUnpublishedChanges(false));
    navigate('../success');
  };

  return (
    <>
      <>
        <HappyHours
          happyHours={tempHappyHours}
          setHappyHours={handleNewTimes}
          openingHours={openingHours}
        />
        <CustomHeading2
          style={{ marginBottom: spacing.md, marginTop: spacing.lg }}
        >
          Menu
        </CustomHeading2>

        {updatingMenu ? (
          <Loader />
        ) : (
          <>
            {showMenuInput ? (
              <HappyHourOptions
                pub={activePub}
                onSubmit={handleSubmit}
                error={error}
                setError={setError}
              />
            ) : (
              <>
                <ActionButton
                  onClick={() => setShowMenuInput(true)}
                  style={{ marginBottom: spacing.lg }}
                >
                  Enter a New Menu
                </ActionButton>
                <Menu
                  drinks={drinks}
                  food={food}
                  setDrinks={setDrinks}
                  setFood={setFood}
                />
                {unpublishedChanges && (
                  <HorizontalWrapper>
                    <ActionButton cancel={'true'} onClick={handleCancel}>
                      Cancel
                    </ActionButton>
                    <ActionButton onClick={handlePublishChanges}>
                      Publish Changes
                    </ActionButton>
                  </HorizontalWrapper>
                )}
              </>
            )}
          </>
        )}
      </>
    </>
  );
};

const HorizontalWrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
`;
