export interface HappyHour {
  startTime: string;
  endTime: string;
}

export const Days = {
  0: '0',
  1: '1',
  2: '2',
  3: '3',
  4: '4',
  5: '5',
  6: '6',
} as const;

export type HappyHoursByDay = Record<keyof typeof Days, HappyHour[]>;
export type OpeningHours = google.maps.places.PlaceOpeningHoursPeriod[];

export interface Pub {
  googlePlaceId: string;
  name: string;
  website: string;
  latitude: number;
  longitude: number;
  happyHours: HappyHoursByDay;
  drinks: MenuItem[];
  food: MenuItem[];
  openingHours: OpeningHours;
  vicinity: string;
  accountId?: string | null;
  events?: PubEvent[];
  rating?: number;
  userRatingsTotal?: number;
  dineOut?: DineOut;
}

export const cuisineTypes = [
  'Indigenous',
  'Afghan/Middle Eastern',
  'African',
  'American',
  'Bar & Grill',
  'BBQ',
  'Beligian',
  'British',
  'Cafes/Bistro',
  'Cajun',
  'Canadian',
  'Caribbean',
  'Chinese',
  'Cocktail Lounge',
  'Cooking Schools',
  'Craft Brewery',
  'Desserts & Ice Cream Parlours',
  'European',
  'Family',
  'Filipino',
  'French',
  'German',
  'Greek',
  'Indian',
  'International',
  'Italian',
  'Japanese',
  'Korean',
  'Malaysian',
  'Mediterranean',
  'Mexican & Latin American',
  'Moroccan & Lebanese',
  'Nepalese',
  'Pan Asian',
  'Pizza',
  'Public House',
  'Pubs',
  'Seafood',
  'South-East Asian',
  'Spanish/Portuguese',
  'Steak & Seafood',
  'Steakhouse',
  'Taiwanese',
  'Thai',
  'Vegan & Vegetarian',
  'Vietnamese',
  'West Coast',
  'Persian',
  'Himalayan',
];

export type CuisineType = (typeof cuisineTypes)[number];

const dineOutMenuTypes = ['brunch', 'lunch', 'dinner', 'dineouttogo', 'veg'];
export type DineOutMenuType = (typeof dineOutMenuTypes)[number];

export type DineOutMenu = {
  price: number | null;
  backupPrice?: string;
  acceptingReservations: boolean;
  type: DineOutMenuType;
  glutenFreeOption: boolean;
  vegetarianOption: boolean;
  takeoutOption: boolean;
  menuText: string;
};

export interface DineOut {
  phone: string;
  email: string;
  bookingUrl?: string;
  imageUrl: string;
  dineOutUrl: string;
  cuisine: CuisineType;
  description: string;
  menus: DineOutMenu[];
}

export interface DineOutItem {
  label: string;
  subLabel: string;
}

export interface MenuItem {
  label: string;
  price: string;
}

export interface Menus {
  drinks: MenuItem[];
  food: MenuItem[];
}

export type HappyHourInfo = Menus & {
  happyHours: HappyHoursByDay;
  link: string;
};

export type MenusAndHappyHours = Menus & { happyHours: HappyHoursByDay };
export type PlaceType = google.maps.places.Autocomplete;
/**
 * admin - App admin - Myself and Liam
 * owner - An account owner, full control of other users, etc.
 * account-admin - Same control as owner for now but not the actual owner
 * client - Limited scoped permissions, tbd.
 */
export type UserRole = 'admin' | 'owner' | 'account-admin' | 'client';

export type User = {
  id: string;
  accountId: string | null;
  firstName: string;
  lastName: string;
  email: string;
  password?: string;
  role: UserRole;
};

export interface Account {
  id: string;
  name: string;
  ownerId: string | null;
  users: User[];
  pubs: Pub[];
}

export const eventTypes = [
  'trivia',
  'music',
  'art',
  'bingo',
  'comedy',
  'karaoke',
  'dance',
  'club-night',
  'sport',
  'dating',
  'networking',
  'food-drink',
  'competition',
  'other',
] as const;

export type EventType = (typeof eventTypes)[number];
export type RecurrenceType = 'daily' | 'weekly' | 'bi-weekly' | 'monthly';
export type RecurrenceFrequency =
  | null
  | 'first'
  | 'second'
  | 'third'
  | 'fourth';
export type DateOnly = string; // DATEONLY: 2021-07-06

export const buttonTextOptions = [
  'Book Now',
  'Book Tickets',
  'Reserve a Table',
  'More Info',
] as const;

export type ButtonTextOption = (typeof buttonTextOptions)[number];

export interface RecurrenceDate {
  date: DateOnly; // DATEONLY: 2021-07-06
  times?: { startTime: string; endTime?: string };
}

export interface PubEvent {
  id?: string; // Optional as new events won't have an ID assigned until created server side
  pubId: string;
  name: string;
  description: string;
  price: number;
  bookingUrl?: string;
  buttonText?: ButtonTextOption;
  phone?: string;
  email?: string;
  type: EventType;
  spotlightEvent: boolean;
  imageUrl?: string;
  times: { startTime: string; endTime?: string };
  date: DateOnly; // DATEONLY: 2021-07-06
  recurrenceDates?: RecurrenceDate[];
}

// These will be handled on the front end. Individual events placed in the DB? How can they be managed as a single entity then? Dates array?
// recurrenceType?: RecurrenceType;
// recurrenceFrequency?: RecurrenceFrequency;
// recurrenceDay?: number;
// recurrenceEndDate?: DateOnly; // DATEONLY: 2021-07-06

export type EventWithFile = PubEvent & { imgFile?: File | undefined | null };
