import React from 'react';
import { useNavigate } from 'react-router-dom';
import { Loader } from '../../../../components/loader';
import { useHandleRefresh } from '../../../../features/shared/hooks/use-handle-refresh';
import { MenuOptionInstructions } from '../../../../shared/components/menu-options-instructions';
import { bulkEventsPath, manualEventPath } from '../../../routes';

export const NewEventsOptions = () => {
  const { loading } = useHandleRefresh();
  const navigate = useNavigate();

  if (loading) {
    return <Loader />;
  }

  const handleBulkClick = () => {
    navigate(bulkEventsPath);
  };

  const handleManualClick = () => {
    navigate(manualEventPath);
  };

  return (
    <>
      <p>How would you like to add your events?</p>
      <MenuOptionInstructions
        heading='Automatically (Recommended)'
        description='Input a list of events to be automatically processed. Recommended
            even for a single event.'
        buttonText='Automatically'
        onClick={handleBulkClick}
      />
      <MenuOptionInstructions
        heading='Manually'
        description='Input individual event details one-by-one.'
        buttonText='Manually'
        onClick={handleManualClick}
      />
    </>
  );
};
