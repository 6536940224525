export const displayTime = (time: string) => {
  const dateTime = new Date(`01-01-1970 ${time}`);
  const hours = dateTime.getHours();
  const mins = dateTime.getMinutes();
  const displayMins = mins === 0 ? '' : `:${mins}`;
  if (hours === 24 || hours === 0) return `12${displayMins}am`;

  return hours === 12
    ? `${hours}${displayMins}pm`
    : hours > 12
    ? `${hours - 12}${displayMins}pm`
    : `${hours}${displayMins}am`;
};
