import React from 'react';
import { Loader } from '../../../../components/loader';
import { PubRow } from '../../../../features/shared/components/pub-row';
import { useAppDispatch, useAppSelector } from '../../../../store';
import { selectAdminAccountsList } from '../../../../store/admin-selectors';
import { selectDineOutPubs } from '../../../../store/app-selectors';
import { selectLoggedInUser } from '../../../../store/user-selectors';
import { Pub } from '../../../../types';
import { ButtonCell, Cell, HeadingRow, StyledTable } from '../styled';

export const DineOutTab = () => {
  const dineOutPubs = useAppSelector(selectDineOutPubs);
  const accounts = useAppSelector(selectAdminAccountsList);
  const dispatch = useAppDispatch();
  const user = useAppSelector(selectLoggedInUser);
  const isAdmin = user?.role === 'admin';

  const handleDeleteDineOut = async (pubToDelete: Pub) => {
    const deleteConfirmed = confirm(
      // `Are you sure you want to delete the Dine Out menu for: \n\n${pubToDelete.name}`
      `Not hooked up yet`
    );

    if (deleteConfirmed) {
      // TODO: Only delete the Dine Out menu from here
      // There may be a happy hour listing for the business. To delete the business
      // use the businesses list.
      // const response = await deletePub(pubToDelete.googlePlaceId);
      // if (response?.status === 200) {
      //   dispatch(
      //     setPubs(
      //       pubs.filter(
      //         (pubData) => pubData.googlePlaceId !== pubToDelete.googlePlaceId
      //       )
      //     )
      //   );
      // }
    }
  };

  if (dineOutPubs.length === 0 || accounts.length === 0) {
    return <Loader />;
  }

  return (
    <>
      <StyledTable>
        <HeadingRow>
          <Cell>ID</Cell>
          <Cell>Name</Cell>
          <Cell>Address</Cell>
          <Cell>Owner</Cell>
          <ButtonCell></ButtonCell>
        </HeadingRow>
        {dineOutPubs.map((pub) => (
          <PubRow
            key={pub.googlePlaceId}
            pub={pub}
            isAdmin={isAdmin}
            accounts={accounts}
            onDelete={handleDeleteDineOut}
          />
        ))}
      </StyledTable>
    </>
  );
};
