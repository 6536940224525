import Button from '@mui/material/Button';
import * as React from 'react';
import { useNavigate } from 'react-router-dom';
import { deleteAccount } from '../../../../api/accounts/delete-account';
import { useAppDispatch, useAppSelector } from '../../../../store';
import { selectAdminAccountsList } from '../../../../store/admin-selectors';
import { setAdminAccountsList } from '../../../../store/admin-slice';
import { setActiveAccount } from '../../../../store/app-slice';
import { Account } from '../../../../types';
import { ButtonCell, Cell, HeadingRow, Row, StyledTable } from '../styled';

export const AccountsTab = () => {
  const accounts = useAppSelector(selectAdminAccountsList);
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const handleRowClick = (account: Account) => {
    dispatch(setActiveAccount(account));
    navigate(account.id);
  };

  const handleDelete = async (accountToDelete: Account) => {
    const deleteConfirmed = confirm(
      `Are you sure you want to delete this account: \n\n${accountToDelete.name}`
    );

    if (deleteConfirmed) {
      const response = await deleteAccount(accountToDelete.id);

      if (response?.status === 200) {
        const newAccountsList = accounts.filter(
          (account) => account.id !== accountToDelete.id
        );
        dispatch(setAdminAccountsList(newAccountsList));
      }
    }
  };

  return (
    <>
      <StyledTable>
        <HeadingRow>
          <Cell>ID</Cell>
          <Cell>Name</Cell>
          <Cell>Owner User ID</Cell>
          <ButtonCell></ButtonCell>
        </HeadingRow>
        {accounts.map((account) => {
          return (
            <Row key={account.id} onClick={() => handleRowClick(account)}>
              <Cell>{account.id}</Cell>
              <Cell>{`${account.name}`}</Cell>
              <Cell>{account.ownerId}</Cell>
              <ButtonCell>
                <Button
                  onClick={(e) => {
                    e.stopPropagation();
                    handleDelete(account);
                  }}
                >
                  Delete
                </Button>
              </ButtonCell>
            </Row>
          );
        })}
      </StyledTable>
    </>
  );
};
