import FormControl from '@mui/material/FormControl';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import dayjs, { Dayjs } from 'dayjs';
import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { spacing } from '../../../../components/styled';
import { StyledLabel } from '../../../../features/shared/components/styled-form';
import { pastDate } from '../helpers/past-date';

interface Props {
  date: string;
  setDate: (newDate: string) => void;
  endTime?: string;
}

export const DateInput = ({ date, setDate, endTime }: Props) => {
  const [open, setOpen] = useState(false);
  const [warning, setWarning] = useState(pastDate(date, endTime));

  useEffect(() => {
    setWarning(pastDate(date, endTime));
  }, [date]);

  const handleChange = (newValue: Dayjs | null) => {
    setWarning(false);
    if (newValue) {
      setDate(dayjs(newValue).format());
      setOpen(false);
    }
  };

  return (
    <Container>
      <FormControl style={{ width: '100%' }}>
        <StyledLabel>Date*</StyledLabel>
        <StyledDatePicker
          open={open}
          value={date ? dayjs(date) : null}
          onChange={handleChange}
          onClose={() => setOpen(false)}
          disablePast
          slotProps={{
            textField: { onClick: () => setOpen(true) },
            openPickerButton: { onClick: () => setOpen(!open) },
          }}
        />
        {warning && (
          <span style={{ color: 'red' }}>This date is in the past</span>
        )}
      </FormControl>
    </Container>
  );
};

const Container = styled.div`
  margin-bottom: ${spacing.lg};
`;

const StyledDatePicker = styled(DatePicker)`
  background-color: white;

  input {
    &:hover {
      cursor: pointer;
    }
  }
`;
