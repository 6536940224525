import React from 'react';
import { Route, Routes } from 'react-router-dom';
import { ProtectedRoute } from '../../../../components/protected-route';
import { Dashboard } from '../../../dashboard/dashboard';
import { DashboardEvents } from '../../../dashboard/events/dashboard-events';
import { DashboardPubs } from '../../../dashboard/pubs/dashboard-pubs';
import { BusinessRoot } from './business-root';

export const DashboardRoot = () => {
  return (
    <Routes>
      <Route path='' element={<ProtectedRoute element={Dashboard} />}>
        <Route path='' element={<ProtectedRoute element={DashboardPubs} />} />
        <Route
          path='businesses'
          element={<ProtectedRoute element={DashboardPubs} />}
        />
        <Route
          path='events'
          element={<ProtectedRoute element={DashboardEvents} />}
        />
      </Route>
      <Route
        path=':googlePlaceId/*'
        element={<ProtectedRoute element={BusinessRoot} />}
      />
      <Route
        path='businesses/:googlePlaceId/*'
        element={<ProtectedRoute element={BusinessRoot} />}
      />
    </Routes>
  );
};
