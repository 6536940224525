import React from 'react';
import styled from 'styled-components';
import { ActionButton } from '../../../../../features/shared/components/styled';
import { FormWrapper } from '../../../../../features/shared/components/styled-form';
import { readableDate } from '../../../../../helpers/date';
import { displayTime } from '../../../../../helpers/time';
import { PubEvent } from '../../../../../types';

interface Props {
  events: PubEvent[];
  handleNextClick: () => void;
}

export const BulkEventsResult = ({ events, handleNextClick }: Props) => {
  return (
    <StyledFormWrapper>
      <p>Success!</p>
      <p>{`We processed ${events.length} events.`}</p>
      <p>Here's a brief summary of the events we captured:</p>
      {events.map((event) => (
        <HorizontalWrapper key={`${event.date}-${event.times.startTime}`}>
          <StyledDate>{`${readableDate(event.date)}:`}</StyledDate>
          <StyledName>{`${event.name} `}</StyledName>
          <StyledTime>{` ${displayTime(event.times.startTime)}`}</StyledTime>
        </HorizontalWrapper>
      ))}
      <p>
        If any events have been missed, you can add them individually
        afterwards.
      </p>
      <p>You can now add more details for each event.</p>
      <ActionButton onClick={handleNextClick}>Get Started</ActionButton>
      {/* <ActionButton onClick={handleNextClick}>Skip & Publish</ActionButton> */}
    </StyledFormWrapper>
  );
};

const StyledFormWrapper = styled(FormWrapper)`
  width: 100%;
  max-width: 600px;
  margin-left: auto;
  margin-right: auto;
  text-align: center;
`;

const HorizontalWrapper = styled.div`
  display: flex;
  flex-direction: row;
`;

const StyledDate = styled.span`
  flex-basis: 25%;
`;
const StyledName = styled.span`
  flex-basis: 40%;
`;
const StyledTime = styled.span`
  flex-basis: 35%;
`;
