import FormControl from '@mui/material/FormControl';
import MenuItem from '@mui/material/MenuItem';
import Select, { SelectChangeEvent } from '@mui/material/Select';
import React from 'react';
import styled from 'styled-components';
import { spacing } from '../../../../components/styled';
import { StyledLabel } from '../../../../features/shared/components/styled-form';
import { EventType, eventTypes } from '../../../../types';

interface Props {
  eventType: EventType;
  setEventType: (newType: EventType) => void;
}

export const EventTypeSelect = ({ eventType, setEventType }: Props) => {
  const handleChange = (event: SelectChangeEvent<unknown>) => {
    setEventType(event.target.value as EventType);
  };

  return (
    <Container>
      <FormControl style={{ width: '100%' }}>
        <StyledLabel htmlFor='event-type-input'>Event Type</StyledLabel>
        <StyledSelect
          id='event-type-input'
          value={eventType}
          onChange={handleChange}
          size='small'
        >
          {eventTypes.map((event) => {
            return (
              <StyledMenuItem key={event} value={event}>
                {event}
              </StyledMenuItem>
            );
          })}
        </StyledSelect>
      </FormControl>
    </Container>
  );
};

const Container = styled.div`
  margin-bottom: ${spacing.lg};
`;

const StyledSelect = styled(Select)`
  min-width: 150px;
  margin-top: ${spacing.sm};
  background-color: white;
  text-transform: capitalize;
`;

const StyledMenuItem = styled(MenuItem)`
  min-width: 150px;
  text-transform: capitalize;
`;
