import React from 'react';
import { removeEvent } from '../../../api/events/remove-event';
import { Loader } from '../../../components/loader';
import { EventsTable } from '../../../features/shared/components/events-table';
import { useUpdatePubsState } from '../../../features/shared/hooks/use-update-pubs-state';
import { useAppSelector } from '../../../store';
import { selectUserPubs } from '../../../store/user-selectors';
import { Pub, PubEvent } from '../../../types';

export const DashboardEvents = () => {
  const usersPubs = useAppSelector(selectUserPubs);
  const { updatePubsState } = useUpdatePubsState();

  if (!usersPubs) {
    return <Loader />;
  }

  const events = usersPubs.reduce(
    (acc: PubEvent[], p) => (p.events ? [...acc, ...p.events] : acc),
    []
  );

  const handleDeleteEvent = async ({ id, name, pubId }: PubEvent) => {
    const deleteConfirmed = confirm(
      `Are you sure you want to delete: \n\n${name}`
    );

    if (id && deleteConfirmed) {
      const response = await removeEvent(id);

      if (response?.status === 200) {
        const pub = usersPubs.find((pub) => pub.googlePlaceId === pubId);

        if (pub) {
          const filteredEvents = pub.events?.filter(
            (eventData) => eventData.id !== id
          );
          const updatedPub: Pub = { ...pub, events: filteredEvents };
          updatePubsState(updatedPub);
        }
      }
    }
  };

  return (
    <>
      {/* TODO: Handle this better. Provide a button followed by a dropdown to choose the business. */}
      <p>To add a new event, first select a listing on the businesses tab.</p>
      <EventsTable events={events} onDelete={handleDeleteEvent} />
    </>
  );
};
