import React from 'react';
import { removePub } from '../../../api';
import { Loader } from '../../../components/loader';
import {
  ButtonCell,
  Cell,
  HeadingRow,
  StyledTable,
} from '../../../routes/admin/dashboard/styled';
import { useAppSelector } from '../../../store';
import { selectAdminAccountsList } from '../../../store/admin-selectors';
import { selectLoggedInUser } from '../../../store/user-selectors';
import { Pub } from '../../../types';
import { useUpdatePubsState } from '../hooks/use-update-pubs-state';
import { PubRow } from './pub-row';

interface Props {
  pubs: Pub[];
}

export const PubsTab = ({ pubs }: Props) => {
  const accounts = useAppSelector(selectAdminAccountsList);
  const user = useAppSelector(selectLoggedInUser);
  const isAdmin = user?.role === 'admin';
  const { deletePubsState } = useUpdatePubsState();

  const handleDeletePub = async (pubToDelete: Pub) => {
    const deleteConfirmed = confirm(
      `Are you sure you want to delete: \n\n${pubToDelete.name}`
    );

    if (deleteConfirmed) {
      const response = await removePub(pubToDelete.googlePlaceId);

      if (response?.status === 200) {
        deletePubsState(pubToDelete);
      }
    }
  };

  if (!pubs) {
    return <Loader />;
  }

  return (
    <>
      <StyledTable>
        <HeadingRow>
          <Cell>ID</Cell>
          <Cell>Name</Cell>
          <Cell>Address</Cell>
          {isAdmin ? (
            <>
              <Cell>Account</Cell>
              <ButtonCell />
              <ButtonCell />
            </>
          ) : (
            <ButtonCell />
          )}
        </HeadingRow>
        {pubs.map((pub) => (
          <PubRow
            isAdmin={isAdmin}
            key={pub.googlePlaceId}
            pub={pub}
            accounts={accounts}
            onDelete={handleDeletePub}
          />
        ))}
      </StyledTable>
    </>
  );
};
