import { TimePicker } from '@mui/x-date-pickers/TimePicker';
import dayjs from 'dayjs';
import React, { useState } from 'react';
import styled from 'styled-components';
import { spacing } from '../../../components/styled';

interface TimeProps {
  startTime: boolean;
  value: string | undefined | null;
  onTimeChange: (time: string | null) => void;
  customText?: string;
  handleCustomTextClick?: () => void;
}

export const TimeInput = ({ startTime, value, onTimeChange }: TimeProps) => {
  const label = startTime ? 'Start Time' : 'End Time';
  const [open, setOpen] = useState(false);

  const handleChange = (value: dayjs.Dayjs | null) => {
    const mins = value?.minute();
    const minsString = mins === 0 ? '00' : mins;
    onTimeChange(`${value?.hour()}:${minsString}`);
  };

  return (
    <StyledContainer>
      <StyledTimePicker
        open={open}
        onClose={() => setOpen(false)}
        label={label}
        minutesStep={15}
        skipDisabled={true}
        value={value ? dayjs(`2018-04-13 ${value}`) : null}
        onChange={handleChange}
        onAccept={handleChange}
        slotProps={{
          textField: { size: 'small', onClick: () => setOpen(!open) },
          inputAdornment: { onClick: () => setOpen(!open) },
        }}
      />
    </StyledContainer>
  );
};

const StyledContainer = styled.div`
  margin-top: ${spacing.md};
`;

const StyledTimePicker = styled(TimePicker)`
  width: 100%;
  max-width: 140px;
  background-color: white;

  input {
    &:hover {
      cursor: pointer;
    }
  }
`;
