import { TextareaAutosize } from '@mui/material';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import styled from 'styled-components';
import { processEventsText } from '../../../../../api/events/process-events-text';
import { Loader } from '../../../../../components/loader';
import { spacing } from '../../../../../components/styled';
import { ActionButton } from '../../../../../features/shared/components/styled';
import {
  FormWrapper,
  StyledForm,
} from '../../../../../features/shared/components/styled-form';
import { useAppSelector } from '../../../../../store';
import { selectActivePub } from '../../../../../store/app-selectors';
import { PubEvent } from '../../../../../types';
import { businessEventsRoute } from '../../../../routes';

interface Props {
  setEvents: (events: PubEvent[]) => void;
}

export const BulkEventsForm = ({ setEvents }: Props) => {
  const navigate = useNavigate();
  const activePub = useAppSelector(selectActivePub);
  const [error, setError] = useState('');
  const [text, setText] = useState('');
  const [loading, setLoading] = useState(false);

  if (!activePub) {
    // Might need to just navigate to the dashboard
    return <Loader />;
  }

  const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    setLoading(true);

    try {
      const newEvents = await processEventsText(activePub.googlePlaceId, text);
      setEvents(newEvents);
    } catch (error) {
      setError(String(error));
    }
    setLoading(false);
  };

  if (loading) {
    return <Loader />;
  }

  return (
    <LocalizationProvider dateAdapter={AdapterDayjs}>
      <StyledFormWrapper>
        {error !== '' ? (
          <>
            <p>There was an error processing the data</p>
            <ActionButton
              onClick={() => {
                setError('');
              }}
            >
              Retry
            </ActionButton>
          </>
        ) : (
          <StyledForm onSubmit={handleSubmit}>
            <p>Paste in the information for your events.</p>
            <p>Include as much information as possible.</p>
            <StyledTextareaAutosize
              value={text}
              placeholder={`TUES 4TH
Set Dancing 7PM
Join us for a fun and energetic night of dancing!
$5
Book now at www.booking.ca/dancing

WED 5TH
Paint Night 7PM
Unleash your creativity at our Paint Night!
$10
More info at www.information.ca/paint

FRI 7TH
Music 8:30PM
Enjoy an unforgettable night of live music!
$10
Book now at www.booking.ca/music`}
              onChange={(e) => setText(e.target.value)}
              minRows={4}
            />
            <StyledActionButton
              type='button'
              cancel={'true'}
              onClick={() =>
                navigate(businessEventsRoute(activePub.googlePlaceId))
              }
            >
              Cancel
            </StyledActionButton>
            <StyledActionButton type='submit' disabled={text === ''}>
              Next
            </StyledActionButton>
          </StyledForm>
        )}
      </StyledFormWrapper>
    </LocalizationProvider>
  );
};

const StyledFormWrapper = styled(FormWrapper)`
  width: 100%;
  max-width: 600px;
  margin-left: auto;
  margin-right: auto;
  margin-top: 0;
`;

const StyledActionButton = styled(ActionButton)`
  && {
    margin-left: ${spacing.md};
    margin-right: ${spacing.md};
  }
`;

const StyledTextareaAutosize = styled(TextareaAutosize)`
  resize: vertical;
  width: 100%;
  min-height: 40px;
  margin-bottom: 10px;
  padding: ${spacing.sm};
`;
