import Button from '@mui/material/Button';
import * as React from 'react';
import { useNavigate } from 'react-router-dom';
import { deleteUser } from '../../../../api';
import { useAppDispatch, useAppSelector } from '../../../../store';
import { selectAdminUsersList } from '../../../../store/admin-selectors';
import { setAdminUsersList } from '../../../../store/admin-slice';
import { setActiveUser } from '../../../../store/app-slice';
import { User } from '../../../../types';
import { ButtonCell, Cell, HeadingRow, Row, StyledTable } from '../styled';

export const UsersTab = () => {
  const users = useAppSelector(selectAdminUsersList);
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const handleRowClick = (user: User) => {
    dispatch(setActiveUser(user));
    navigate(user.id);
  };

  const handleDelete = async (userToDelete: User) => {
    const deleteConfirmed = confirm(
      `Are you sure you want to delete: \n\n${userToDelete.firstName} ${userToDelete.lastName}`
    );

    if (deleteConfirmed) {
      const response = await deleteUser(userToDelete.id);

      if (response?.status === 200) {
        const newUsersList = users.filter(
          (user) => user.id !== userToDelete.id
        );
        dispatch(setAdminUsersList(newUsersList));
      }
    }
  };

  return (
    <>
      <StyledTable>
        <HeadingRow>
          <Cell>ID</Cell>
          <Cell>Name</Cell>
          <Cell>Email</Cell>
          <Cell>Account ID</Cell>
          <ButtonCell>Role</ButtonCell>
          <ButtonCell></ButtonCell>
        </HeadingRow>
        {users.map((user) => {
          return (
            <Row key={user.id} onClick={() => handleRowClick(user)}>
              <Cell>{user.id}</Cell>
              <Cell>{`${user.firstName} ${user.lastName}`}</Cell>
              <Cell>{user.email}</Cell>
              <Cell>{user.accountId}</Cell>
              <ButtonCell>{user.role}</ButtonCell>
              <ButtonCell>
                <Button
                  onClick={(e) => {
                    e.stopPropagation();
                    handleDelete(user);
                  }}
                >
                  Delete
                </Button>
              </ButtonCell>
            </Row>
          );
        })}
      </StyledTable>
    </>
  );
};
