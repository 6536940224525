import Button from '@mui/material/Button';
import React from 'react';
import { useNavigate } from 'react-router-dom';
import { removeEvent } from '../../../api/events/remove-event';
import { Loader } from '../../../components/loader';
import { EventsTable } from '../../../features/shared/components/events-table';
import { useAppDispatch, useAppSelector } from '../../../store';
import { selectActivePub } from '../../../store/app-selectors';
import { setActiveEvent, setActivePub } from '../../../store/app-slice';
import { PubEvent } from '../../../types';
import { newEventsPath } from '../../routes';

export const BusinessEvents = () => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const pub = useAppSelector(selectActivePub);

  const initialEvent: PubEvent = {
    pubId: pub?.googlePlaceId ?? '',
    name: '',
    description: '',
    price: 0,
    type: 'trivia',
    spotlightEvent: false,
    imageUrl: '',
    times: { startTime: '18:00' },
    date: '',
  };

  const handleDeleteEvent = async ({ id, name }: PubEvent) => {
    const deleteConfirmed = confirm(
      `Are you sure you want to delete: \n\n${name}`
    );

    if (id && deleteConfirmed) {
      const response = await removeEvent(id);

      if (pub?.events && response?.status === 200) {
        const filteredEvents = pub.events.filter(
          (eventData) => eventData.id !== id
        );
        dispatch(setActivePub({ ...pub, events: filteredEvents }));
      }
    }
  };

  const handleNewEventClick = () => {
    dispatch(setActiveEvent(initialEvent));
    navigate(newEventsPath);
  };

  if (!pub) {
    return <Loader />;
  }

  return (
    <>
      <Button onClick={handleNewEventClick}>Add Event</Button>
      <EventsTable events={pub?.events} onDelete={handleDeleteEvent} />
      <Button onClick={handleNewEventClick}>Add Event</Button>
    </>
  );
};
