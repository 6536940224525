import { RootAppState } from './createStore';

export const selectDineOutPubs = (state: RootAppState) => state.app.dineOutPubs;
export const selectToken = (state: RootAppState) => state.app.token;
export const selectActivePub = (state: RootAppState) => state.app.activePub;
export const selectActiveEvent = (state: RootAppState) => state.app.activeEvent;
export const selectActiveAccount = (state: RootAppState) =>
  state.app.activeAccount;
export const selectActiveUser = (state: RootAppState) => state.app.activeUser;
export const selectUnpublishedChanges = (state: RootAppState) =>
  state.app.unpublishedChanges;
export const selectLoadingData = (state: RootAppState) => state.app.loadingData;
