import CloudUploadIcon from '@mui/icons-material/CloudUpload';
import ContentPasteIcon from '@mui/icons-material/ContentPaste';
import LanguageIcon from '@mui/icons-material/Language';
import TextareaAutosize from '@mui/material/TextareaAutosize';
import React, { ChangeEvent, useState } from 'react';
import styled from 'styled-components';
import { Loader } from '../../../../components/loader';
import { colors, spacing } from '../../../../components/styled';
import {
  ActionButton,
  HorizontalWrapper,
  StyledTabButton,
} from '../../../../features/shared/components/styled';
import { useHandleRefresh } from '../../../../features/shared/hooks/use-handle-refresh';
import { MenuOptionInstructions } from '../../../../shared/components/menu-options-instructions';
import { Pub } from '../../../../types';

interface Props {
  pub: Pub;
  onSubmit: (text?: string, link?: string, file?: File | null) => void;
  error: string;
  setError: (error: string) => void;
}

export const HappyHourOptions = ({ pub, onSubmit, error, setError }: Props) => {
  useHandleRefresh();
  const [option, setOption] = useState<number | null>(null);
  const [file, setFile] = useState<File | null>(null);
  const [text, setText] = useState('');
  const [link, setLink] = useState('');
  const nextDisabled =
    (option === 0 && !text) ||
    (option === 1 && !link) ||
    (option == 2 && !file);

  if (!pub) {
    return <Loader />;
  }

  const handleOptionClick = (optionIndex: number) => {
    setError('');
    setOption(optionIndex);
  };

  const handleTextInput = (
    e: React.FocusEvent<HTMLTextAreaElement, Element>
  ) => {
    setFile(null);
    setLink('');
    setText(e.target.value);
  };

  const handleAddUrl = (e: ChangeEvent<HTMLInputElement>) => {
    if (e.target.value !== '' && !e.target.value.includes('http')) {
      setLink(`https://${e.target.value}`);
    } else {
      setLink(e.target.value);
    }
    setFile(null);
    setText('');
  };

  const handleFileUploadClick = (
    e: React.MouseEvent<HTMLButtonElement, MouseEvent>
  ) => {
    if (file) {
      e.preventDefault();
    }
    setError('');
  };

  const handleFileUpload = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (!event.target.files?.[0].name.includes('.pdf')) {
      setError('That is not a PDF file.');
    } else {
      const file = event.target.files?.[0];
      if (file) {
        setFile(file);
        setLink('');
        setText('');
      }
      setError('');
    }
  };

  const handleSubmit = async () => {
    if (option === 0) {
      onSubmit(text);
    } else if (option === 1) {
      onSubmit(undefined, link);
    } else if (option === 2) {
      onSubmit(undefined, undefined, file);
    }
  };

  return (
    <StyledContainer>
      <>
        <StyledP>
          How would you like to add your happy hour information?
        </StyledP>
        {option === null && (
          <>
            {error && <span style={{ color: 'red' }}>{error}</span>}
            <MenuOptionInstructions
              heading='Paste Menu (Recommended)'
              description='Copy and paste in your menu. This is the most accurate option.'
              buttonText='Paste Menu'
              onClick={() => handleOptionClick(0)}
            />
            <MenuOptionInstructions
              heading='Website Link'
              description='Scrape your happy hour information from a provided URL. Be aware that this may not work for some websites.'
              buttonText='Enter URL'
              onClick={() => handleOptionClick(1)}
            />
            <MenuOptionInstructions
              heading='Upload PDF'
              description='Upload your menu and times from a PDF file.'
              buttonText='Upload PDF'
              onClick={() => handleOptionClick(2)}
            />
          </>
        )}
        {option !== null && (
          <>
            <StyledButtonsWrapper>
              <StyledTabButton
                $active={option === 0}
                onClick={() => handleOptionClick(0)}
              >
                <ContentPasteIcon />
                <span>Paste Menu</span>
              </StyledTabButton>
              <StyledTabButton
                $active={option === 1}
                onClick={() => handleOptionClick(1)}
              >
                <LanguageIcon />
                <span>Menu URL</span>
              </StyledTabButton>
              <StyledTabButton
                $active={option === 2}
                onClick={() => handleOptionClick(2)}
              >
                <CloudUploadIcon />
                <span>Upload PDF</span>
              </StyledTabButton>
            </StyledButtonsWrapper>
            {error && <span style={{ color: 'red' }}>{error}</span>}
          </>
        )}
      </>
      {option === 0 && (
        <>
          <p>Copy and paste your happy hour menu and times.</p>
          <StyledTextareaAutosize
            value={text}
            placeholder={`Happy Hour Mon-Fri 3-6pm. 

HAPPY HOUR
DRINKS
Guinness (20 oz) $7.50
Magners (20 oz) $7.50
Stella Artois (20 oz) $7.50
Red/White Wine 9oz $10
Red/White Wine 6oz $7
Baby Guinness 1oz $5
House Highballs 1oz $6.25
Bucket of the Day $27.50
  `}
            onChange={handleTextInput}
            minRows={4}
          />
        </>
      )}
      {option === 1 && (
        <>
          <StyledP>Enter the URL for your happy hour menu.</StyledP>
          <Warning style={{ marginBottom: 0, textAlign: 'center' }}>
            Warning! Image menus or menus nested under tabs or sub-sections will
            not work.
          </Warning>
          <Warning style={{ marginTop: spacing.sm, textAlign: 'center' }}>
            Results may not be as expected.
          </Warning>
          <StyledInput
            placeholder='https://www.business.com/happy-hour-menu'
            value={link}
            onChange={handleAddUrl}
          />
        </>
      )}
      {option === 2 && !file && (
        <>
          <StyledActionButton
            onClick={handleFileUploadClick}
            component='label'
            role={undefined}
            variant='contained'
            tabIndex={-1}
            startIcon={<CloudUploadIcon />}
          >
            <span>Choose a File</span>
            <VisuallyHiddenInput
              type='file'
              onChange={handleFileUpload}
              multiple
            />
          </StyledActionButton>
        </>
      )}
      {option === 2 && !!file && (
        <HorizontalWrapper style={{ alignItems: 'baseline' }}>
          <span style={{ marginRight: spacing.md }}>{`File: `}</span>
          <StyledInput value={file.name} disabled />
          <ActionButton
            component='label'
            role={undefined}
            variant='contained'
            tabIndex={-1}
            style={{ minWidth: 'fit-content', marginLeft: spacing.lg }}
          >
            <span>Change File</span>
            <VisuallyHiddenInput
              type='file'
              onChange={handleFileUpload}
              multiple
            />
          </ActionButton>
        </HorizontalWrapper>
      )}
      {option !== null && !nextDisabled && (
        <ActionButton onClick={handleSubmit} style={{ marginLeft: 'auto' }}>
          Next
        </ActionButton>
      )}
    </StyledContainer>
  );
};

const StyledContainer = styled.div`
  display: flex;
  flex-direction: column;
  margin: ${spacing.lg};
`;

const StyledP = styled.p`
  margin-bottom: 10px;
`;

const Warning = styled.p`
  weight: 500;
  color: orange;
`;

const StyledButtonsWrapper = styled(HorizontalWrapper)`
  margin-bottom: ${spacing.lg};
`;

const StyledTextareaAutosize = styled(TextareaAutosize)`
  resize: vertical;
  width: 100%;
  min-height: 40px;
  margin-bottom: 10px;
  padding: ${spacing.sm};
`;

const StyledInput = styled.input`
  width: 100%;
  margin-bottom: 10px;
  padding: ${spacing.sm};
  padding-left: ${spacing.md};
  color: ${colors.slate};
`;

const VisuallyHiddenInput = styled.input({
  clip: 'rect(0 0 0 0)',
  clipPath: 'inset(50%)',
  height: 1,
  overflow: 'hidden',
  position: 'absolute',
  bottom: 0,
  left: 0,
  whiteSpace: 'nowrap',
  width: 1,
});

const StyledActionButton = styled(ActionButton)`
  max-width: fit-content;
`;
