import React from 'react';
import { removeEvent } from '../../../../api/events/remove-event';
import { EventsTable } from '../../../../features/shared/components/events-table';
import { useUpdateEventsState } from '../../../../features/shared/hooks/use-update-events-state';
import { useAppSelector } from '../../../../store';
import {
  selectAdminEventsList,
  selectAdminPubs,
} from '../../../../store/admin-selectors';
import { PubEvent } from '../../../../types';

export const AdminEvents = () => {
  const events = useAppSelector(selectAdminEventsList);
  const adminPubs = useAppSelector(selectAdminPubs);
  const { deleteEventState } = useUpdateEventsState();

  const handleDeleteEvent = async (event: PubEvent) => {
    const { id, name, pubId } = event;
    const deleteConfirmed = confirm(
      `Are you sure you want to delete: \n\n${name}`
    );

    if (id && deleteConfirmed) {
      const response = await removeEvent(id);

      if (response?.status === 200) {
        const pub = adminPubs.find((pub) => pub.googlePlaceId === pubId);

        if (pub) {
          deleteEventState(pub, event);
        }
      }
    }
  };

  return <EventsTable events={events} onDelete={handleDeleteEvent} />;
};
