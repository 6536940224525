import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { updateEvent } from '../../../../api/events/update-event';
import { Loader } from '../../../../components/loader';
import { useHandleRefresh } from '../../../../features/shared/hooks/use-handle-refresh';
import { useUpdateEventsState } from '../../../../features/shared/hooks/use-update-events-state';
import { useAppDispatch, useAppSelector } from '../../../../store';
import {
  selectActiveEvent,
  selectActivePub,
} from '../../../../store/app-selectors';
import { setActivePub } from '../../../../store/app-slice';
import { selectUserPubs } from '../../../../store/user-selectors';
import { PubEvent } from '../../../../types';
import { businessEventsRoute, dashboardRoute } from '../../../routes';
import { EventForm } from './event-form';

export const EventOverview = () => {
  const { loading } = useHandleRefresh();
  const navigate = useNavigate();
  const activeEvent = useAppSelector(selectActiveEvent);
  const activePub = useAppSelector(selectActivePub);
  const dispatch = useAppDispatch();
  const usersPubs = useAppSelector(selectUserPubs);
  const { updateEventsState } = useUpdateEventsState();
  const [error, setError] = useState('');

  useEffect(() => {
    if (!loading && !activeEvent && !activePub) {
      navigate(dashboardRoute);
    } else if (!loading && activePub && !activeEvent) {
      navigate(businessEventsRoute(activePub.googlePlaceId));
    } else if (!loading && activeEvent && !activePub && usersPubs.length > 0) {
      const activePub = usersPubs?.find(
        (pub) => pub.googlePlaceId === activeEvent.pubId
      );
      if (activePub) {
        dispatch(setActivePub(activePub));
      } else {
        navigate(dashboardRoute);
      }
    }
  }, [loading]);

  if (!activeEvent || !activePub) {
    return <Loader />;
  }

  const onCancel = () => {
    navigate(businessEventsRoute(activePub.googlePlaceId));
  };

  const onSubmit = async (
    newEventData: PubEvent,
    imgFile: File | undefined | null
  ) => {
    try {
      setError('');
      const newEvent = await updateEvent(newEventData, imgFile);
      updateEventsState(activePub, newEvent);

      navigate(businessEventsRoute(activePub.googlePlaceId));
    } catch (error) {
      setError('There was an error processing the event. Please try again.');
    }
  };

  return (
    <>
      {error && <span style={{ color: 'red' }}>{error}</span>}
      <EventForm event={activeEvent} onSubmit={onSubmit} onCancel={onCancel} />
    </>
  );
};
