import { useCallback, useState } from 'react';
import { useGooglePlaces } from '../../features/shared/hooks/use-google-places';
import { useUpdatePubsState } from '../../features/shared/hooks/use-update-pubs-state';
import { Pub } from '../../types';
import { baseUrl } from '../base-url';

export const useUpdatePub = () => {
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState('');
  const { getPlaceDetails } = useGooglePlaces();
  const { updatePubsState } = useUpdatePubsState();

  const updatePubRequest = useCallback((pub: Pub) => {
    const token = localStorage.getItem('token');

    const fetchData = async () => {
      return await fetch(`${baseUrl}/api/pub`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token}`,
        },
        body: JSON.stringify(pub),
      });
    };

    return fetchData();
  }, []);

  const updatePub = async (pub: Pub) => {
    setLoading(true);
    const ratingData = await getPlaceDetails(pub.googlePlaceId, [
      'rating',
      'user_ratings_total',
    ]);

    const newPubData = {
      ...pub,
      rating: ratingData?.rating,
      userRatingsTotal: ratingData?.user_ratings_total,
    };

    try {
      const response = await updatePubRequest(newPubData);

      if (response?.status === 200 || response?.status === 201) {
        updatePubsState(newPubData);
      } else {
        setError(
          'There was an error updating the business. Please try again or report this issue.'
        );
      }
    } catch (error) {
      console.error(error);
      setError(
        'There was an error updating the business. Please try again or report this issue.'
      );
    }
    setLoading(false);
  };

  return { loading, error, updatePub };
};
