import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { bulkCreateEvents } from '../../../../../api/events/bulk-create-events';
import { Loader } from '../../../../../components/loader';
import { useHandleRefresh } from '../../../../../features/shared/hooks/use-handle-refresh';
import { useUpdateEventsState } from '../../../../../features/shared/hooks/use-update-events-state';
import { useAppSelector } from '../../../../../store';
import { selectActivePub } from '../../../../../store/app-selectors';
import { EventWithFile, Pub, PubEvent } from '../../../../../types';
import { businessEventsRoute } from '../../../../routes';
import { EventForm } from '../../components/event-form';
import { BulkEventsForm } from './bulk-events-form';
import { BulkEventsResult } from './bulk-events-result';

export const BulkEvents = () => {
  const { loading: refreshLoading } = useHandleRefresh();
  const navigate = useNavigate();
  const activePub = useAppSelector(selectActivePub);
  const [error, setError] = useState('');
  const [loading, setLoading] = useState(false);
  const [events, setEvents] = useState<EventWithFile[]>([]);
  const [index, setIndex] = useState(0);
  const [showInstructions, setShowInstructions] = useState(true);
  const cancelButtonText = index === 0 ? 'Cancel' : 'Previous';
  const actionButtonText = index === events.length - 1 ? 'Publish All' : 'Next';
  const { updateEventsState } = useUpdateEventsState();

  if (refreshLoading || loading || !activePub) {
    // Might need to just navigate to the dashboard
    return <Loader />;
  }

  const handlePrevious = (
    newEvent: PubEvent,
    imgFile: File | undefined | null
  ) => {
    if (index === 0) {
      const canelConfirmed = confirm(
        `Are you sure you want to cancel? All the processed data will be lost.`
      );

      if (canelConfirmed) {
        navigate(businessEventsRoute(activePub.googlePlaceId));
      }
    } else {
      updateEvent(newEvent, imgFile);
      setIndex(index - 1);
    }
  };

  const updateEvent = (
    newEvent: PubEvent,
    imgFile: File | undefined | null
  ) => {
    const newEvents = events.map((event, i) => {
      if (i === index) {
        return { ...newEvent, imgFile };
      } else {
        return event;
      }
    });

    setEvents(newEvents);
    if (index !== events.length - 1) {
      setIndex(index + 1);
    }
  };

  const handleBulkPublish = async () => {
    try {
      setLoading(true);
      const publishedEvents = await bulkCreateEvents(events);

      // Update state. Setting the events as activePub won't be updated for each iteration.
      const newPub: Pub = {
        ...activePub,
        events: [...(activePub.events ?? []), ...publishedEvents],
      };
      publishedEvents.forEach((e) => updateEventsState(newPub, e));

      setLoading(false);
      navigate(businessEventsRoute(activePub.googlePlaceId));
    } catch (error) {
      setError('There was an error bulk publishing.');
    }
  };

  const handleActionButton = (
    newEvent: PubEvent,
    imgFile: File | undefined | null
  ) => {
    updateEvent(newEvent, imgFile);

    if (index === events.length - 1) {
      handleBulkPublish();
    } else {
      setIndex(index + 1);
    }
  };

  const onDelete = () => {
    const deleteConfirmed = confirm(
      `Are you sure you want to delete this event: \n\n${events[index].name}`
    );

    if (deleteConfirmed) {
      const filteredEvents = events.filter((_e, i) => i !== index);
      setEvents(filteredEvents);
    }
  };

  return (
    <>
      {events.length === 0 ? (
        <BulkEventsForm setEvents={setEvents} />
      ) : showInstructions ? (
        <BulkEventsResult
          events={events}
          handleNextClick={() => setShowInstructions(false)}
        />
      ) : (
        <EventForm
          event={events[index]}
          onSubmit={handleActionButton}
          actionButtonText={actionButtonText}
          cancelButtonText={cancelButtonText}
          onDelete={onDelete}
          onCancel={handlePrevious}
          index={index}
          listLength={events.length}
        />
      )}
    </>
  );
};
