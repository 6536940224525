import React, { useState } from 'react';
import styled from 'styled-components';
import { spacing } from '../../../components/styled';
import { PubEvent } from '../../../types';

interface Props {
  event: PubEvent;
  handleChange: (newValue: PubEvent) => void;
  handleRemove: () => void;
}

export const EventListItem = ({ event, handleChange, handleRemove }: Props) => {
  const [tempItem, setTempItem] = useState(event.name);
  const [tempPrice, setTempPrice] = useState(event.price);

  const handleLabelUpdate = (
    e: React.FocusEvent<HTMLInputElement, Element>
  ) => {
    // const newData = { label: event.name, price: e.target.value };
    const newData = { ...event };
    handleChange(newData);
  };

  const handlePriceUpdate = (
    e: React.FocusEvent<HTMLInputElement, Element>
  ) => {
    // const newData = { label: event.name, price: e.target.value };
    const newData = { ...event };
    handleChange(newData);
  };

  return (
    <HorizontalInputs>
      <StyledData>{event.name}</StyledData>
      {/* <StyledData>{event.description}</StyledData>
      <StyledData>{event.price}</StyledData> */}
      <StyledData>{event.date}</StyledData>
      {/* <StyledData>{event.bookingUrl}</StyledData>
      <StyledData>{event.moreInfoUrl}</StyledData> */}
    </HorizontalInputs>
  );
};

const HorizontalInputs = styled.div`
  display: flex;
  margin-bottom: 5px;
`;

const StyledData = styled.span`
  margin-right: ${spacing.md};
`;
