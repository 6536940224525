import React from 'react';
import { useNavigate } from 'react-router-dom';
import styled from 'styled-components';
import { Loader } from '../../components/loader';
import { spacing } from '../../components/styled';
import { NavigationHeader } from '../../features/shared/components/navigation-header';
import { useHandleRefresh } from '../../features/shared/hooks/use-handle-refresh';
import { AppSectionWrapper } from '../../shared/components/section-wrapper';
import { useAppDispatch, useAppSelector } from '../../store';
import { selectLoggedInAccount } from '../../store/user-selectors';

export const AccountOverview = () => {
  const navigate = useNavigate();
  useHandleRefresh();
  const dispatch = useAppDispatch();
  const account = useAppSelector(selectLoggedInAccount);

  if (!account) {
    return <Loader />;
  }

  return (
    <div>
      <NavigationHeader />
      <AppSectionWrapper>
        <Container>
          <h2>Account Overview</h2>
          <Content>
            <h3>{account.name}</h3>
            {/* <p>{account.ownerId}</p> */}
          </Content>
        </Container>
      </AppSectionWrapper>
    </div>
  );
};

const Container = styled.div`
  width: 100%;
  margin-top: ${spacing.lg};
  margin-bottom: ${spacing.lg};
`;

const Content = styled.div`
  margin: ${spacing.xl} 0;
`;
