import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { dashboardRoute } from '../../routes/routes';
import { useAppDispatch } from '../../store';
import { setToken } from '../../store/app-slice';
import { setLoggedInAccount, setLoggedInUser } from '../../store/user-slice';
import { Account, User } from '../../types';
import { baseUrl } from '../base-url';

type InviteResponse = {
  account: Account;
  user: User;
  accessToken: string;
};

export const useAcceptInvite = () => {
  const [error, setError] = useState(false);
  const [loading, setLoading] = useState(false);
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const acceptInvite = async (
    accountId: string,
    user: {
      email: string;
      password: string;
      firstName: string;
      lastName: string;
    },
    turnstileToken: string
  ) => {
    setLoading(true);
    try {
      const response = await fetch(`${baseUrl}/api/account/invite`, {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({ accountId, user, turnstileToken }),
      });

      if (response.status !== 201) {
        setError(true);
        setLoading(false);
      } else {
        const { account, user, accessToken }: InviteResponse =
          await response.json();
        dispatch(setToken(accessToken));
        dispatch(setLoggedInUser(user));
        dispatch(setLoggedInAccount(account));
        localStorage.setItem('token', accessToken);
        navigate(dashboardRoute);
        setLoading(false);

        return response;
      }
    } catch (err) {
      setError(true);
      setLoading(false);
      console.error('Error while accepting account invite.');
    }
  };

  return { error, loading, acceptInvite };
};
