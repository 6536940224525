import dayjs from 'dayjs';

const days: Record<number, string> = {
  0: 'Sunday',
  1: 'Monday',
  2: 'Tuesday',
  3: 'Wednesday',
  4: 'Thursday',
  5: 'Friday',
  6: 'Saturday',
};

const monthString: Record<number, string> = {
  0: 'Jan',
  1: 'Feb',
  2: 'Mar',
  3: 'Apr',
  4: 'May',
  5: 'June',
  6: 'July',
  7: 'Aug',
  8: 'Sep',
  9: 'Oct',
  10: 'Nov',
  11: 'Dec',
};

export const monthStringLongForm: Record<number, string> = {
  0: 'January',
  1: 'February',
  2: 'March',
  3: 'April',
  4: 'May',
  5: 'June',
  6: 'July',
  7: 'August',
  8: 'September',
  9: 'October',
  10: 'November',
  11: 'December',
};

export const displayDay = (day: string | number) => {
  const index = typeof day === 'string' ? parseInt(day) : day;

  return days[index];
};

export const dateStringValue = (day: number) => {
  if ([1, 21, 31].includes(day)) {
    return 'st';
  } else if ([2, 22].includes(day)) {
    return 'nd';
  } else if ([3, 23].includes(day)) {
    return 'rd';
  } else {
    return 'th';
  }
};

export const readableDate = (dateString?: string) => {
  const date = dayjs(dateString).toDate();

  const day = date.getDate();
  const month = date.getMonth();

  const dayString = displayDay(date.getDay());
  const numberString = dateStringValue(day);
  const monthValue = monthString[month];

  return `${dayString}, ${monthValue} ${day}${numberString}`;
};

export const dateStringToDate = (dateString: string) => {
  return new Date(`${dateString} 01:00:00`);
};
