import React, { useEffect } from 'react';

import Button from '@mui/material/Button';
import { useNavigate } from 'react-router-dom';
import styled from 'styled-components';
import { readableDate } from '../../../helpers/date';
import { displayTime } from '../../../helpers/time';
import { ButtonCell, Cell, Row } from '../../../routes/admin/dashboard/styled';
import { dashboardRoute, eventRoute } from '../../../routes/routes';
import { useAppDispatch } from '../../../store';
import { setActiveEvent, setActivePub } from '../../../store/app-slice';
import { PubEvent } from '../../../types';
import { useLookupPub } from '../hooks/use-lookup-pub';

interface Props {
  event: PubEvent;
  onDelete: (event: PubEvent) => void;
}

export const EventRow = ({ event, onDelete }: Props) => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const eventId = event.id;
  const startDate = readableDate(event.date);
  const endDate = '';
  const pub = useLookupPub(event.pubId);
  const { startTime, endTime } = event.times;
  const timeText = endTime
    ? `${displayTime(startTime)} - ${displayTime(endTime)}`
    : displayTime(startTime);

  useEffect(() => {
    // Shouldn't be possible to get here
    if (!eventId) {
      navigate(dashboardRoute);
      return;
    }
  }, [eventId]);

  const handleRowClick = () => {
    dispatch(setActivePub(pub));
    dispatch(setActiveEvent(event));
    navigate(eventRoute(event));
  };

  return (
    <Row key={event.id} onClick={handleRowClick}>
      <ButtonCell>
        <StyledImage src={event.imageUrl} />
      </ButtonCell>
      <Cell>{event.name}</Cell>
      <Cell>{pub?.name}</Cell>
      <Cell>{`${startDate}${endDate}`}</Cell>
      <Cell>{timeText}</Cell>
      <Cell>${event.price}</Cell>
      <ButtonCell>
        <Button onClick={handleRowClick}>Edit</Button>
      </ButtonCell>
      <ButtonCell>
        <Button
          onClick={(e) => {
            e.stopPropagation();
            onDelete(event);
          }}
        >
          Delete
        </Button>
      </ButtonCell>
    </Row>
  );
};

const StyledImage = styled.img`
  width: 80%;
`;
