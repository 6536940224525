import React from 'react';
import { PubsTab } from '../../../../features/shared/components/pubs-tab';
import { useAppSelector } from '../../../../store';
import { selectAdminPubs } from '../../../../store/admin-selectors';

export const AdminPubs = () => {
  const adminPubs = useAppSelector(selectAdminPubs);

  return <PubsTab pubs={adminPubs} />;
};
