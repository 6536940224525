import React from 'react';

import Button from '@mui/material/Button';
import { useNavigate } from 'react-router-dom';
import { ButtonCell, Cell, Row } from '../../../routes/admin/dashboard/styled';
import { useAppDispatch } from '../../../store';
import { setActivePub } from '../../../store/app-slice';
import { Account, Pub } from '../../../types';

interface Props {
  pub: Pub;
  isAdmin: boolean;
  accounts: Account[];
  onDelete: (pub: Pub) => void;
}

export const PubRow = ({ pub, isAdmin, accounts, onDelete }: Props) => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const accountDetails = accounts.find((acc) => acc.id === pub.accountId);

  const handleRowClick = () => {
    dispatch(setActivePub(pub));
    navigate(pub.googlePlaceId);
  };

  const handleDelete = (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    {
      e.stopPropagation();
      onDelete(pub);
    }
  };

  return (
    <Row key={pub.googlePlaceId} onClick={handleRowClick}>
      <Cell>{pub.googlePlaceId}</Cell>
      <Cell>{pub.name}</Cell>
      <Cell>{pub.vicinity}</Cell>
      {isAdmin ? (
        <>
          <Cell>{accountDetails?.name ?? ''}</Cell>
          <ButtonCell>
            <Button onClick={handleRowClick}>Edit</Button>
          </ButtonCell>
          <ButtonCell>
            <Button onClick={handleDelete}>Delete</Button>
          </ButtonCell>
        </>
      ) : (
        <ButtonCell>
          <Button onClick={handleRowClick}>Edit</Button>
        </ButtonCell>
      )}
    </Row>
  );
};
