import React from 'react';
import { Outlet } from 'react-router-dom';
import styled from 'styled-components';
import { Loader } from '../../components/loader';
import { spacing } from '../../components/styled';
import { HeadingTabs } from '../../features/shared/components/heading-tabs';
import { NavigationHeader } from '../../features/shared/components/navigation-header';
import { useHandleRefresh } from '../../features/shared/hooks/use-handle-refresh';
import { AppSectionWrapper } from '../../shared/components/section-wrapper';

const tabHeadings = [{ heading: 'Businesses' }, { heading: 'Events' }];

export const Dashboard = () => {
  const { loading } = useHandleRefresh();

  if (loading) {
    return <Loader />;
  }

  return (
    <div>
      <NavigationHeader />
      <AppSectionWrapper>
        <Container>
          <h2>Dashboard</h2>
          <HeadingTabs headings={tabHeadings} />
          <TableContainer>
            <Outlet />
          </TableContainer>
        </Container>
      </AppSectionWrapper>
    </div>
  );
};

const Container = styled.div`
  margin-left: auto;
  margin-right: auto;
  margin-top: ${spacing.lg};
  margin-bottom: ${spacing.lg};
  width: 100%;
  max-width: 1200px;
`;

const TableContainer = styled.div`
  margin: ${spacing.lg} 0;
`;
