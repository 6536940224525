import styled from 'styled-components';
import { colors, spacing } from '../../../components/styled';

export const StyledTable = styled.div`
  border: 1px ${colors.slate} solid;
  font-size: 14px;
`;

export const Row = styled.div`
  display: flex;
  border-top: 0.5px ${colors.slate} solid;
  align-items: center;
  cursor: pointer;

  &:hover {
    background-color: rgb(81, 166, 249, 0.1);
  }
`;

export const HeadingRow = styled(Row)`
  border-bottom: 1px ${colors.slate} solid;
  cursor: auto;

  &:hover {
    background-color: white;
  }
`;

export const Cell = styled.div`
  padding: ${spacing.md};
  flex-basis: 30%;
`;

export const ButtonCell = styled(Cell)`
  flex-basis: 10%;
  text-align: center;
`;
