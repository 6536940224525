import React from 'react';
import { PubsTab } from '../../../features/shared/components/pubs-tab';
import { useAppSelector } from '../../../store';
import { selectUserPubs } from '../../../store/user-selectors';

export const DashboardPubs = () => {
  const usersPubs = useAppSelector(selectUserPubs);

  return <PubsTab pubs={usersPubs} />;
};
