import { useMapsLibrary } from '@vis.gl/react-google-maps';
import React, { useEffect, useRef, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import styled from 'styled-components';
import { spacing } from '../../../../../components/styled';
import { ActionButton } from '../../../../../features/shared/components/styled';
import { transformPlaceToPub } from '../../../../../features/shared/helpers/transform-place-to-pub';
import { AppSectionWrapper } from '../../../../../shared/components/section-wrapper';
import { useAppDispatch } from '../../../../../store';
import { fullGooglePlaceFieldsList } from '../../../../../store/app-constants';
import { setActivePub } from '../../../../../store/app-slice';
import { PlaceType } from '../../../../../types';
import { newBusinessPath } from '../../../../routes';

export const PubSearch = () => {
  const inputRef = useRef<HTMLInputElement>(null);
  const [autoCompletePlace, setAutoCompletePlace] = useState<PlaceType | null>(
    null
  );
  const placesLib = useMapsLibrary('places');
  const [place, setPlace] = useState<google.maps.places.PlaceResult | null>(
    null
  );
  const [error, setError] = useState('');
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  useEffect(() => {
    if (!placesLib || !inputRef.current) return;

    const options: google.maps.places.AutocompleteOptions = {
      types: ['establishment'],
      componentRestrictions: { country: 'can' },
      fields: fullGooglePlaceFieldsList,
    };

    setAutoCompletePlace(new placesLib.Autocomplete(inputRef.current, options));
  }, [placesLib]);

  useEffect(() => {
    if (!autoCompletePlace) return;

    autoCompletePlace.addListener('place_changed', () => {
      setPlace(autoCompletePlace.getPlace());
    });
  }, [autoCompletePlace]);

  const handleKeyDown = (e: React.KeyboardEvent) => {
    if (e.key === 'Enter') handleNextClick();
  };

  const handleNextClick = () => {
    if (place) {
      try {
        const pubData = transformPlaceToPub(place);

        dispatch(setActivePub(pubData));
        navigate(newBusinessPath(pubData.googlePlaceId));
      } catch (error) {
        setError(`Missing Google Place data for placeId: ${place?.place_id}`);
      }
    }
  };

  return (
    <AppSectionWrapper>
      <Container>
        <div style={{ width: '100%' }}>
          {error !== '' && <span>{error}</span>}
          <>
            <StyleInput
              ref={inputRef}
              style={{ width: '100%' }}
              placeholder='Business name'
              onKeyDown={handleKeyDown}
            />
            <ActionButton
              onClick={handleNextClick}
              disabled={!place}
              style={{ marginLeft: 'auto' }}
            >
              Start
            </ActionButton>
          </>
        </div>
      </Container>
    </AppSectionWrapper>
  );
};

const Container = styled.div`
  margin: 20px;
  width: 100%;
`;

const StyleInput = styled.input`
  width: 100%;
  margin-bottom: 10px;
  padding: ${spacing.md};
  border-radius: ${spacing.md};
  border-width: 0.5px;
`;
