import React from 'react';
import {
  ButtonCell,
  Cell,
  HeadingRow,
  StyledTable,
} from '../../../routes/admin/dashboard/styled';
import { PubEvent } from '../../../types';
import { EventRow } from './event-row';

interface Props {
  events?: PubEvent[];
  onDelete: (event: PubEvent) => void;
}

export const EventsTable = ({ events, onDelete }: Props) => {
  return (
    <>
      <StyledTable>
        <HeadingRow>
          <ButtonCell></ButtonCell>
          <Cell>Name</Cell>
          <Cell>Location</Cell>
          <Cell>Date</Cell>
          <Cell>Time</Cell>
          <Cell>Price</Cell>
          <ButtonCell></ButtonCell>
          <ButtonCell></ButtonCell>
        </HeadingRow>
        {events &&
          events.map((event, index) => (
            <EventRow
              key={event.id ?? index}
              event={event}
              onDelete={() => onDelete(event)}
            />
          ))}
      </StyledTable>
    </>
  );
};
