import React, { forwardRef, PropsWithChildren, Ref } from 'react';
import styled from 'styled-components';
import { desktopBreakpoint } from '../../../app/components/styled';

interface Props {
  backgroundColor?: string;
  transitionBackground?: boolean;
  mobileColumns?: boolean;
  maxWidth?: string;
  isSticky?: boolean;
  stickyTop?: number;
}

export const AppSectionWrapper = forwardRef(
  (
    {
      backgroundColor,
      transitionBackground = false,
      mobileColumns = false,
      maxWidth,
      isSticky = false,
      stickyTop = 0,
      children,
    }: PropsWithChildren<Props>,
    ref: Ref<HTMLDivElement | null>
  ) => {
    return (
      <FullWidthContainer
        $backgroundColor={backgroundColor}
        $isSticky={isSticky}
        $stickyTop={stickyTop}
        $transitionBackground={transitionBackground}
      >
        <StyledSection
          ref={ref as Ref<HTMLDivElement>}
          $mobileColumns={mobileColumns}
          $maxWidth={maxWidth}
        >
          {children}
        </StyledSection>
      </FullWidthContainer>
    );
  }
);

const FullWidthContainer = styled.section<{
  $backgroundColor?: string;
  $isSticky: boolean;
  $stickyTop: number;
  $transitionBackground: boolean;
}>`
  background-color: ${({ $backgroundColor }) => $backgroundColor};
  ${({ $transitionBackground }) =>
    $transitionBackground && `transition: background-color 1000ms linear`};
  width: 100%;
  ${({ $isSticky, $stickyTop }) =>
    $isSticky
      ? `
      position: sticky; 
      top: ${$stickyTop}px;
      z-index: 10;
    `
      : ''}
`;

const StyledSection = styled.div<{
  $mobileColumns: boolean;
  $maxWidth?: string;
}>`
  width: 90%;
  max-width: ${({ $maxWidth }) => $maxWidth ?? '1200px'};
  margin-left: auto;
  margin-right: auto;
  display: flex;
  flex-direction: column;

  @media ${desktopBreakpoint} {
    width: 80%;
    flex-direction: row;
  }
`;
