import { EventType } from '@aws-sdk/client-ses';
import UploadIcon from '@mui/icons-material/Upload';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import React, { useState } from 'react';
import CurrencyInput from 'react-currency-input-field';
import { useNavigate } from 'react-router-dom';
import styled from 'styled-components';
import { Loader } from '../../../../components/loader';
import { spacing } from '../../../../components/styled';
import { ImageUploadInput } from '../../../../features/shared/components/image-input';
import { ActionButton } from '../../../../features/shared/components/styled';
import {
  FormWrapper,
  StyledForm,
  StyledInput,
  StyledLabel,
  StyledTextArea,
} from '../../../../features/shared/components/styled-form';
import { useAppDispatch, useAppSelector } from '../../../../store';
import { selectActivePub } from '../../../../store/app-selectors';
import { setActiveEvent } from '../../../../store/app-slice';
import { PubEvent } from '../../../../types';
import { ButtonTextSelect } from './button-text-select';
import { DateInput } from './date-picker';
import { EventTypeSelect } from './event-type-select';
import { TimePickers } from './time-pickers';

interface Props {
  event: PubEvent;
  onSubmit: (event: PubEvent, imgFile: File | undefined | null) => void;
  actionButtonText?: string;
  cancelButtonText?: string;
  onCancel: (event: PubEvent, imgFile: File | undefined | null) => void;
  onDelete?: () => void;
  index?: number;
  listLength?: number;
}

export const EventForm = ({
  event,
  onSubmit,
  actionButtonText,
  cancelButtonText,
  onCancel,
  onDelete,
  index,
  listLength,
}: Props) => {
  const navigate = useNavigate();
  // const event = useAppSelector(selectActiveEvent);
  const activePub = useAppSelector(selectActivePub);
  const dispatch = useAppDispatch();
  const [imgFile, setImgFile] = useState<File | undefined | null>(null);
  // const [recurring, setRecurring] = useState(false);
  const [error, setError] = useState('');

  if (!event || !activePub) {
    return <Loader />;
  }

  const {
    bookingUrl,
    buttonText,
    date,
    description,
    email,
    imageUrl,
    name,
    phone,
    times,
    type,
  } = event;
  const publishDisabled = date === '' || name === '';

  const handleChange = (
    param: keyof PubEvent,
    value: string | boolean | number | EventType | string[] | null | undefined
  ) => {
    dispatch(
      setActiveEvent({
        ...event,
        times: { ...times },
        pubId: activePub.googlePlaceId,
        [param]: value,
      })
    );
  };

  const handleCancel = async () => {
    onCancel(event, imgFile);
  };

  const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    onSubmit(event, imgFile);
  };

  const handleNewStartTime = (newStartTime: string) => {
    dispatch(
      setActiveEvent({
        ...event,
        times: { ...times, startTime: newStartTime },
        pubId: activePub.googlePlaceId,
      })
    );
  };

  const handleNewEndTime = (newEndTime: string | null) => {
    if (newEndTime) {
      dispatch(
        setActiveEvent({
          ...event,
          times: { ...times, endTime: newEndTime },
          pubId: activePub.googlePlaceId,
        })
      );
    } else {
      dispatch(
        setActiveEvent({
          ...event,
          times: { startTime: times.startTime },
          pubId: activePub.googlePlaceId,
        })
      );
    }
  };

  return (
    <LocalizationProvider dateAdapter={AdapterDayjs}>
      <StyledFormWrapper>
        {index !== undefined && listLength !== undefined && (
          <span style={{ fontStyle: 'italic' }}>{`${
            index + 1
          } of ${listLength}`}</span>
        )}
        {imageUrl && !imgFile && (
          <HorizontalWrapper>
            <StyledImage src={event.imageUrl} />
          </HorizontalWrapper>
        )}
        <StyledForm onSubmit={handleSubmit}>
          <ImageUploadInput
            imgFile={imgFile}
            setImgFile={setImgFile}
            Icon={<UploadIcon />}
            message={imageUrl ? 'Upload New Image' : 'Upload an Image'}
            previewButtonMessage={'Upload New Image'}
            imageMaxWidth={400}
            imageMaxHeight={300}
          />
          <StyledLabel htmlFor='name'>Event Name*</StyledLabel>
          <StyledInput
            id='name'
            value={name ?? ''}
            onChange={(e) => handleChange('name', e.target.value)}
            required
          />
          <div>
            <StyledLabel htmlFor='description'>Description</StyledLabel>
            <StyledTextArea
              id='description'
              value={description ?? ''}
              onChange={(e) => handleChange('description', e.target.value)}
              rows={7}
            />
            {/* {description.length > 200 && (
              <>
                <span style={{ color: 'orange' }}>
                  {`Warning: Events will display best with a description less than
                  200 characters. Current length: ${description.length}`}
                </span>
              </>
            )} */}
          </div>
          <StyledLabel htmlFor='price'>Price*</StyledLabel>
          <HorizontalWrapper>
            <StyledCurrencyInput
              id='price-input'
              name='price-input'
              prefix='$ '
              placeholder='Price'
              value={event.price}
              defaultValue={0}
              decimalsLimit={2}
              onValueChange={(_value, _name, values) =>
                handleChange('price', values?.float)
              }
              required
            />
          </HorizontalWrapper>
          <EventTypeSelect
            eventType={type}
            setEventType={(newType) => handleChange('type', newType)}
          />
          <DateInput
            date={date}
            setDate={(newDate) => handleChange('date', newDate)}
            endTime={times.endTime}
          />
          <TimePickers
            times={times}
            onStartChange={handleNewStartTime}
            onEndChange={handleNewEndTime}
          />
          <StyledLabel htmlFor='booking-url'>Booking/Reserve URL</StyledLabel>
          <StyledInput
            id='booking-url'
            value={bookingUrl ?? ''}
            onChange={(e) => handleChange('bookingUrl', e.target.value)}
            placeholder='https://www.event.com'
          />
          {bookingUrl && (
            <ButtonTextSelect
              buttonText={buttonText}
              setButtonText={(newButtonText) =>
                handleChange('buttonText', newButtonText)
              }
            />
          )}
          <StyledLabel htmlFor='email'>Email</StyledLabel>
          <StyledInput
            id='email'
            value={email ?? ''}
            onChange={(e) => handleChange('email', e.target.value)}
            placeholder={'Booking Email'}
            type='email'
          />
          <StyledLabel htmlFor='name'>Phone</StyledLabel>
          <StyledInput
            id='phone'
            value={phone ?? ''}
            onChange={(e) => handleChange('name', e.target.value)}
            placeholder={'Booking Phone Number'}
            type='tel'
          />
          {/* <p>Is this a recurring event?</p>
          <StyledRadioButton>
            <input
              id='recurring-yes'
              type='radio'
              value='Yes'
              checked={recurring}
              onChange={() => setRecurring(true)}
            />
            <label htmlFor='recurring-yes'>Yes</label>
          </StyledRadioButton>
          <StyledRadioButton>
            <input
              id='recurring-no'
              type='radio'
              value='No'
              checked={!recurring}
              onChange={() => setRecurring(false)}
            />
            <label htmlFor='owner-no'>No</label>
          </StyledRadioButton>
          <br />
          <p /> */}
          {/* <p>Do you want this to be a featured spotlight event? Fee applies.</p>
          <StyledRadioButton>
            <input
              id='spotlight-yes'
              type='radio'
              value='Yes'
              checked={activeEvent?.spotlightEvent ?? false}
              onChange={() => handleChange('spotlightEvent', true)}
            />
            <label htmlFor='spotlight-yes'>Yes</label>
          </StyledRadioButton>
          <StyledRadioButton>
            <input
              id='spotlight-no'
              type='radio'
              value='No'
              checked={!activeEvent?.spotlightEvent ?? true}
              onChange={() => handleChange('spotlightEvent', false)}
            />
            <label htmlFor='owner-no'>No</label>
          </StyledRadioButton>
          <br />
          <p /> */}
          <StyledActionButton
            type='button'
            cancel={'true'}
            onClick={handleCancel}
          >
            {cancelButtonText ?? 'Cancel'}
          </StyledActionButton>
          {onDelete && (
            <StyledDeleteButton type='button' onClick={onDelete}>
              Delete
            </StyledDeleteButton>
          )}
          <StyledActionButton type='submit' disabled={publishDisabled}>
            {actionButtonText ?? 'Publish'}
          </StyledActionButton>
        </StyledForm>
      </StyledFormWrapper>
    </LocalizationProvider>
  );
};

const StyledFormWrapper = styled(FormWrapper)`
  width: 100%;
  max-width: 600px;
  margin-left: auto;
  margin-right: auto;
  margin-top: 0;
  text-align: center;
`;

const StyledImage = styled.img`
  max-width: 400px;
  max-height: 300px;
`;

const HorizontalWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
`;

const StyledActionButton = styled(ActionButton)`
  && {
    margin-left: ${spacing.md};
    margin-right: ${spacing.md};
  }
`;

const StyledDeleteButton = styled(StyledActionButton)`
  && {
    color: red;
    border: 1px red solid;
    background-color: white;
  }
`;

const StyledCurrencyInput = styled(CurrencyInput)`
  width: 100%;
  padding: ${spacing.md};
  border: none;
  border-radius: 5px;
  margin-top: ${spacing.sm};
  margin-bottom: ${spacing.lg};
`;
