import React from 'react';
import styled from 'styled-components';
import { Loader } from '../../../../components/loader';
import { fontSize, spacing } from '../../../../components/styled';
import { DetailsRow } from '../../../../features/shared/components/details-row';
import { NavigationHeader } from '../../../../features/shared/components/navigation-header';
import { useHandleRefresh } from '../../../../features/shared/hooks/use-handle-refresh';
import { AppSectionWrapper } from '../../../../shared/components/section-wrapper';
import { useAppSelector } from '../../../../store';
import { selectActiveAccount } from '../../../../store/app-selectors';
import { AccountDetailsForm } from './account-details-form';

export const AccountDetails = () => {
  useHandleRefresh();
  const account = useAppSelector(selectActiveAccount);

  if (!account) {
    return <Loader />;
  }

  return (
    <div>
      <NavigationHeader />
      <AppSectionWrapper>
        <Container>
          <h2>Account Details</h2>
          <DetailsRow property={'ID'} value={account?.id} />
          <DetailsRow
            property={'Invite Link'}
            value={`https://www.vibeapp.ca/app/invite?account_name=${account.name}&account_id=${account.id}`}
            link
          />
          <Content>
            <AccountDetailsForm account={account} />
          </Content>
        </Container>
      </AppSectionWrapper>
    </div>
  );
};

const Container = styled.div`
  width: 100%;
  margin-top: ${spacing.lg};
  margin-bottom: ${spacing.lg};
`;

const Content = styled.div`
  margin: ${spacing.xl} 0;
`;

const StyledInfo = styled.p`
  margin: 0;
  font-size: ${fontSize.text} !important;
`;
