import FormControl from '@mui/material/FormControl';
import Tooltip from '@mui/material/Tooltip';
import React from 'react';
import styled from 'styled-components';
import { spacing } from '../../../../components/styled';
import { RemoveButton } from '../../../../components/styled/buttons';
import { StyledLabel } from '../../../../features/shared/components/styled-form';
import { TimeInput } from '../../../../features/shared/components/time-input';
import { PubEvent } from '../../../../types';
import { HorizontalInputs } from '../../happy-hours/menu-list';

interface Props {
  times: PubEvent['times'];
  onStartChange: (newTime: string) => void;
  onEndChange: (newTime: string | null) => void;
}

export const TimePickers = ({ times, onStartChange, onEndChange }: Props) => {
  return (
    <Container>
      <FormControl style={{ width: '100%' }}>
        <StyledLabel>Times</StyledLabel>
        <HorizontalInputs
          style={{ alignItems: 'center', justifyContent: 'center' }}
        >
          <TimeInput
            value={times.startTime}
            startTime={true}
            onTimeChange={(newStartTime) => {
              if (newStartTime) {
                onStartChange(newStartTime);
              }
            }}
          />
          <Wrapper>
            <StyledDivider>-</StyledDivider>
            <TimeInput
              value={times.endTime ?? null}
              startTime={false}
              onTimeChange={(newStartTime) => {
                if (newStartTime) {
                  onEndChange(newStartTime);
                }
              }}
            />
            {times.endTime && (
              <Tooltip title='Remove End Time'>
                <StyledRemoveButton
                  color='error'
                  onClick={() => onEndChange(null)}
                >
                  X
                </StyledRemoveButton>
              </Tooltip>
            )}
          </Wrapper>
        </HorizontalInputs>
      </FormControl>
    </Container>
  );
};

const Container = styled.div`
  margin-bottom: ${spacing.xl};
`;

const Wrapper = styled.div`
  display: flex;
  align-items: center;
`;

const StyledDivider = styled.span`
  margin-left: ${spacing.md};
  margin-right: ${spacing.md};
`;

const StyledRemoveButton = styled(RemoveButton)`
  margin: 0 !important;
  padding-top: 0;
`;
