import React, { useEffect, useRef, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import styled from 'styled-components';
import { colors, spacing } from '../../../components/styled';
import {
  accountOverviewRoute,
  adminDashBoardRoute,
  dashboardRoute,
  userOverviewRoute,
} from '../../../routes/routes';
import { AppSectionWrapper } from '../../../shared/components/section-wrapper';
import { useAppDispatch, useAppSelector } from '../../../store';
import { selectUnpublishedChanges } from '../../../store/app-selectors';
import { setActiveUser, setUnpublishedChanges } from '../../../store/app-slice';
import { selectLoggedInUser } from '../../../store/user-selectors';
import { ActionButton, StyledTabLink } from './styled';

export const NavigationHeader = () => {
  const [activeTab, setActiveTab] = useState<number | null>(null);
  const unpublishedChanges = useAppSelector(selectUnpublishedChanges);
  const loggedInUser = useAppSelector(selectLoggedInUser);
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const sectionRef = useRef<HTMLDivElement>(null);
  const [sectionHeight, setSectionHeight] = useState(0);

  useEffect(() => {
    if (location.pathname.includes('admin')) {
      setActiveTab(3);
    } else if (location.pathname.includes('dashboard')) {
      setActiveTab(2);
    } else if (location.pathname.includes('/user/')) {
      setActiveTab(1);
    } else if (location.pathname.includes('/account/')) {
      setActiveTab(0);
    } else {
      setActiveTab(null);
    }
  }, []);

  useEffect(() => {
    if (sectionRef.current) {
      setSectionHeight(sectionRef.current.getBoundingClientRect().y);
    }
  }, [sectionRef]);

  if (!loggedInUser) {
    return <></>;
  }
  const { accountId } = loggedInUser;

  const handleAccountClick = () => {
    dispatch(setActiveUser(loggedInUser));
  };

  const handlePublish = () => {};

  const handleCancelChanges = () => {
    dispatch(setUnpublishedChanges(false));
    navigate('../overview');
  };

  return (
    <AppSectionWrapper
      backgroundColor={colors.paleGrey}
      isSticky={true}
      stickyTop={sectionHeight}
      ref={sectionRef}
    >
      <Container>
        {/* TODO: Remove once production accounts and users have been created */}
        {accountId && (
          <StyledTabLink
            to={accountOverviewRoute(accountId)}
            $active={activeTab === 0}
            onClick={handleAccountClick}
          >
            Account
          </StyledTabLink>
        )}
        <StyledTabLink
          to={userOverviewRoute(loggedInUser.id)}
          $active={activeTab === 1}
          onClick={handleAccountClick}
        >
          User
        </StyledTabLink>
        <StyledTabLink $active={activeTab === 2} to={dashboardRoute}>
          Dashboard
        </StyledTabLink>
        {loggedInUser?.role === 'admin' && (
          <StyledTabLink to={adminDashBoardRoute} $active={activeTab === 3}>
            Admin Dashboard
          </StyledTabLink>
        )}
        {/* {unpublishedChanges && (
          <>
            <StyledNavActionButton onClick={handleCancelChanges}>
              Discard Changes
            </StyledNavActionButton>
            <StyledNavActionButton onClick={handlePublish}>
              Publish Changes
            </StyledNavActionButton>
          </>
        )} */}
      </Container>
    </AppSectionWrapper>
  );
};

const Container = styled.div`
  width: 100%;
  display: flex;
  background-color: ${colors.paleGrey};
  margin-top: ${spacing.sm};
  margin-bottom: ${spacing.sm};
`;

const StyledNavActionButton = styled(ActionButton)`
  && {
    margin-top: 0;
    margin-left: ${spacing.lg};
  }
`;
